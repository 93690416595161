import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
            <div style={{display:'flex',width:'100%',height:100,flexDirection:'column',justifyContent:'space-around',alignItems:'center',marginTop:'90%'}}>
                <a href={'itms-apps://itunes.apple.com/app/apple-store/id1476150936?mt=8'}><img src={require('./ios.png')} style={{width:150,height:45}}/></a>
                <a href={'https://www.installonair.com/app-download-link/kARNsI'}><img src={require('./android.png')} style={{width:150,height:45}}/></a>
            </div>
      </header>
    </div>
  );
}

export default App;
